/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:4ecfd81e-36fa-48e3-b9d1-35d6bef852bf",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_wNO8Y2Amf",
    "aws_user_pools_web_client_id": "6a91ufk0k1p7cumnhjmmgt3o3k",
    "oauth": {}
};


export default awsmobile;
